body {
    margin: 0;
    font-family: 'Vazir FD-WOL', 'tahoma', 'Ubuntu', 'Vazir' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    overflow: hidden;
}

.scroll_hide::-webkit-scrollbar {
    width: 0px;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

